// ProfileTile.js
import React from 'react';

const ProfileTile = () => {
  return (
    <div className="tile profile-tile">
      <h2>Jacob</h2>
      <p>Loren Ipsum</p>
    </div>
  );
};

export default ProfileTile;
