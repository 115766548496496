import logo from './logo.svg';
import './App.css';
import { app, analytics } from "./Config/firebase"; // Adjust the path as needed
import React from 'react';
import ProfileTile from './Components/ProfileTile';


function App() {
  return (
    <div className="grid-container">
      <ProfileTile />
      <ProfileTile />
      <ProfileTile />
      {/* Add other tiles here */}
    </div>
  );
};

export default App;
